$main_blue: #0ae7f8;

.pool-detail-title {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
}

.pool-detail-title-blue {
  margin: 0;
  font-size: 11pt;
  color: $main_blue;
}

.pool-detail-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.pool-detail-img-big {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pl-65 {
  padding-left: 65px;
}

.right-img {
  margin-left: -15px;
}

.pool-detail-pair {
  font-size: 20px;
}

.pool-detail-pair-name {
  margin: 10px 0px 0 10px;
}

.pool-detail-conversion {
  margin: -5px 0 0 -30px;
  p {
    font-size: 10pt;
  }

  img {
    width: 20px;
  }
}
.v-center{
  display: flex;
  align-items: center;
}
.variable-screen {
  height: 100%;
}

@media (max-width: 1200px) {
  .personal-title {
    margin-top: 1.5rem;
  }
}

@media (max-width: 1164px) {
  .pool-detail-conversion {
    p {
      font-size: 9pt;
    }
  }
}

@media (max-width: 1158px) {
  .pool-detail-conversion {
    margin: -5px 0 0 -10px;
  }
}

@media (max-width: 1112px) {
  .pool-detail-conversion {
    margin: -5px 0 0 20px;
  }
}

@media (max-width: 1084px) {
  .pool-detail-conversion {
    p {
      font-size: 8pt;
    }
  }
}

@media (max-width: 770px) {
  .variable-screen {
    height: auto;
  }
}
