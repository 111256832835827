$main_blue: #0ae7f8;

.dropdown-menu {
  background-color: #000000;
  border: 1px solid $main_blue;
  border-radius: 10px;
  padding-top: 0;
  padding-bottom: 0;
  li a {
    color: #ffffff;
  }
}

.dropdown-item {
  padding-top: 10px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #144144;
  color: #ffffff;
  border-radius: 10px;
}

.loading-pools {
  span {
    background-color: #144144;
    border: 1px solid #0ae7f8;
    padding: 30px 30px 15px 30px;
    border-radius: 10px;
  }
}

.input-my-pools {
  margin-left: 6px; 
  width: 15px; 
  height: 15px; 
  min-height: 0;
}

.click {
  cursor: pointer;
}

.warning-loading-pools {
  span {
    background-color: #441414;
    border: 1px solid #f80a0a;
    padding: 30px 30px 15px 30px;
    border-radius: 10px;
  }
}

.selector__control {
  width: 100% !important;
  height: 50px !important;
  background: rgba(0, 0, 0, 0.2) !important;
  border: 1px solid $main_blue !important;
  border-radius: 10px !important;
  font-family: "Monda Regular" !important;
  font-size: 13px !important;
  line-height: 26px !important;
  padding: 5px 20px !important;
  color: #ffffff !important;
}

.selector__value-container {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  display: flex !important;
  align-content: center !important;
}

.selector__input-container {
  margin: 0 !important;
  padding: 0 !important;
  color: white !important;
}

.selector__single-value {
  color: white !important;
}

.selector__menu {
  background-color: #121212 !important;
  border: 1px solid $main_blue !important;
  border-radius: 10px !important;
}

.selector__indicator-separator {
  display: none;
}

.selector__option--is-focused {
  background-color: #144144 !important;
  border-radius: 10px !important;
}

.settings {
  display: flex;
  justify-content: center;
}

.info_msg {
  color: #ffffff;
}

.custom-align {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 769px) {
  .custom-align {
    display: flex;
    justify-content: flex-end;
  }

  .button-bigbox {
    justify-content: flex-start;
  }
}

@media (max-width: 575px) {
  .custom-align {
    display: flex;
    justify-content: center;
  }

  .button-bigbox {
    margin-top: 12px;
    justify-content: center;
  }
}
