$main_blue: #0ae7f8;

h5 {
  font-size: 15pt;
}

.title {
  margin: 0;
  font-size: 11pt;
  color: #b7b7b7;
}

.info-icon {
  margin: -2px 0 0 2px;
}

.warning-icon {
  margin-top: -12px;
  margin-left: 5px;
}

.dollar {
  color: $main_blue;
  font-weight: bold;
}
