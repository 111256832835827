.alert-success {
  border: 1px solid #3cff00;
  background-color: #3cff006a;
  padding: 15px 10px 10px 10px;
  color: #3cff00;
  font-weight: bold;
  border-radius: 10px;
  width: 70%;
}

h6 {
  color: #ffffff;
}
