$main_blue: #0ae7f8;
$second_blue: #1b31dd;

/* LIQUID BUTTON */
.liquid-button {
  height: 32px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid $second_blue;
}
.liquid-button-full-height {
  height: 100%;
  font-size: 16px;
  line-height: 26px;
  padding: 5px 20px;
  padding-bottom: 3px;
}
.liquid-button span {
  font-size: 10pt;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  color: #fff;
  z-index: 1;
}

.liquid-button .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 200px;
  height: 200px;
  background: $second_blue;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

.liquid-button .liquid::after {
  content: "";
  width: 200%;
  height: 200%;
  position: absolute;
  top: -7px;
  left: 35%;
  transform: translate(-50%, -75%);
  background: #000000;
}

.liquid-button .liquid::after {
  border-radius: 40%;
  background: #000000;
  animation: animate 10s linear infinite;
}

.liquid-button:hover .liquid {
  top: -120px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@media (max-width: 1201px) {
  .liquid-button .liquid {
    width: 491px;
    height: 391px;
  }
  .liquid-button .liquid::after {
    left: 50%;
    width: 150%;
    height: 200%;
  }

  .liquid-button .liquid {
    top: -160px;
  }

  .liquid-button:hover .liquid {
    top: -300px;
  }
}

@media (max-width: 1080px) {
  .liquid-button .liquid::after {
    left: 40%;
  }
}

@media (max-width: 930px) {
  .liquid-button .liquid::after {
    left: 35%;
  }
}

@media (max-width: 700px) {
  .liquid-button .liquid::after {
    width: 70%;
    height: 70%;
    left: 123px;
    top: 100px;
    animation: animate 5s linear infinite;
  }
}

@media (max-width: 540px) {
  .liquid-button .liquid::after {
    width: 50%;
    height: 50%;
    left: 101px;
    top: 120px;
    animation: animate 5s linear infinite;
  }
}

@media (max-width: 410px) {
  .liquid-button .liquid::after {
    left: 58px;
  }
}
