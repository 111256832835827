.nav-link, .nav-link:focus {
  color: #a1a1a1;
}

.navbar-nav {
  width: 100%;
}

.nav-item {
  margin: 0 0 0 30px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #ffffff;
}

.navbar-nav > .active {
  color: #ffffff;
}

.nav-link:hover {
  color: #ffffff;
}

.hamburguer-toggler {
  border: 0;
  background: transparent;
  color: #ffffff;
  visibility: hidden;
}

@media (max-width: 767px) {
  .navbar-nav {
    margin: 0;
  }
  .hamburguer-toggler {
    border: 0;
    background: transparent;
    color: #ffffff;
    visibility: visible;
  }
}
