$main_blue: #0ae7f8;

.create-pool-input,
.pair-btn,
.add-rewards-input-disabled
{
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  margin: 0 auto;
  font-size: 10pt;
  color: #ffffff;
  border: 1px solid $main_blue;
  border-radius: 10px;
  background-color: #000000;
}

.pair-btn{
  padding-left: 12px;
}

.add-rewards-input-disabled {
  background-color: #121212;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ffffff;
}

input {
  min-height: 43px;
  width: 100%;
  background-color: #ffffff;
  border: 2px solid black;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 0 10px 10px;
}

.black {
  color: black;
}

.react-datetime-picker__wrapper {
  border-radius: 10px;
  border: 0 !important;
  background-color: #171717;
  font-family: "Gotham Book";
  padding: 0 0 0 15px;
  min-height: 43px;
}

.create-pool-box{
  width: 60%;
}


@media (max-width: 600px){
  .create-pool-box{
    width: 100%;
  }
}
